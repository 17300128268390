<template>
  <div>
    <topmenu :comname="comname"></topmenu>
    <div class="flex">
      <div class="widht1240 bothendsleft">
        <div class="t-map">
          您当前的位置：
          <span>{{ maptitle }}</span>
        </div>
        <div class="widht200 fl">
          <contact></contact>
        </div>
        <div class="widht1030 bg-white fr">
          <div class="apply">
            <el-button v-if="id=='2'" icon="el-icon-edit-outline" type="goon" @click="dialogFormVisible = true"
              >申请观展</el-button
            >
          </div>

          <div v-if="isshowlist" class="listinfor" v-loading="loading">
            <ul>
              <li v-for="item in list" :key="item.id" class="compaylist_li">
                <!-- <a :href="'http://www.yyj086.com/Room/index?iUiIdentifier=' + item.iUiIdentifier" target="_blank">  :rel="noFollow(item.url)"-->
                <a :href="item.url" :rel="noFollow(item.url)" target="_blank">

                  <p v-if="item.vBoothNumber == null?false:true" class="iUiIdentifier" style="border-radius: 0 0 5px 0;">{{item.vBoothNumber}}</p>
                  <el-image
                    :src="
                      item.nvcLogo?item.nvcLogo:require('@/assets/images/dtmr.png')
                    "
                    :alt="item.nvcLogo ? item.nvcCompanyName : ''"
                    fit="contain"
                    :title="item.nvcCompanyName"
                    style="width: 185px; height: 98px;"
                  ></el-image>
                  <span :title="item.nvcCompanyName">{{
                    item.nvcCompanyName != null &&
                    item.nvcCompanyName.length > 20
                      ? comJs.limitNum(item.nvcCompanyName, 20)
                      : item.nvcCompanyName
                  }}</span>
                </a>
              </li>
            </ul>
            <el-pagination
              background
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-count="totalPage"
              :current-page="currentPage"
              :page-size="pagesize"
              :hide-on-single-page="true"
              class="t_page"
            ></el-pagination>
          </div>
          <nocont v-else></nocont>
        </div>
      </div>
      <div v-if="id=='2'" class="bothendsright">
        <div class="top-title">新增观展申请</div>
        <vue-seamless-scroll :data="listData" class="seamless-warp" :class-option="defaultOption">
          <ul>
            <li
              v-for="(item, i) in listData"
              :key="i"
              style="display: flex; justify-content: space-between"
            >
              <span v-text="item.title"></span>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    <bottominfo></bottominfo>
    <el-dialog title="申请观展" width="30%" :visible.sync="dialogFormVisible" :close-on-click-modal="false" center>
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="企业名称：" :label-width="formLabelWidth" prop="companyName">
          <el-input v-model="form.companyName" autocomplete="off" ></el-input>
        </el-form-item>
        <el-form-item label="联系人：" :label-width="formLabelWidth" prop="contactsName">
          <el-input v-model="form.contactsName" autocomplete="off" ></el-input>
        </el-form-item>
        <el-form-item label="联系方式：" :label-width="formLabelWidth" prop="phone">
          <el-input v-model="form.phone" autocomplete="off" ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
        <el-button @click="resetForm('form')">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
import nocont from "@/components/public/nocont";
export default {
  name: "enterprise",
  data() {
    return {
      id: "",
      maptitle: "",
      comname: "",
      list: [],
      isshowlist: true,
      loading: true,
      currentPage: 1, //当前页
      pagesize: 25, //每页显示条数
      totalPage: 0, //总页数
      listData: [
        {
          title: "深圳市*****技有限公司",
        },
        {
          title: "北京*****技装备有限公司",
        },
        {
          title: "杭州*****术有限公司",
        },
        {
          title: "天津******有限公司",
        },
        {
          title: "天津****发展有限公司"
        },
        {
          title: "安徽*******设备有限公司",
        },
        {
          title: "北京****科技发展有限公司",
        },
        {
          title: "山东*****设备有限公司",
        },
        {
          title: "陇南******研究科技有限公司",
        },
        {
          title: "长春****科技有限公司",
        },
        {
          title: "中科*****（苏州）科技有限公司",
        },
        {
          title: "德尼****技术（苏州）有限公司",
        },
        {
          title: "河北****具制造有限公司",
        },
        {
          title: "北京****科技管理中心",
        },
        {
          title: "霸州市*****机具有限公司",
        },
        {
          title: "河北**贸易有限公司",
        },
        {
          title: "威海****设备制造有限公司",
        },
        {
          title: "北京*****设备有限公司",
        },
        {
          title: "梧州***科技有限公司",
        },
        {
          title: "潍坊****机械有限公司",
        },
        {
          title: "汝南县**应急设备有限公司",
        },
        {
          title: "深圳市**科技有限公司",
        },
        {
          title: "航天****技术股份有限公司",
        },
        {
          title: "湖南****装备科技有限公司",
        },
        {
          title: "北京中关村******研究院有限公司",
        },

        {
          title: "浙江****工程有限公司",
        },
        {
          title: "鹰眼****有限公司",
        },

        {
          title: "西安****工程新材料有限公司",
        },
        {
          title: "安徽*****技术有限公司",
        },
        {
          title: "梅思安****设备有限公司",
        },
        {
          title: "新兴****工厂",
        },
        {
          title: "中国******工程局有限公司",
        },
        {
          title: "河南*****科技股份有限公司",
        },
        {
          title: "奥来****有限公司",
        },
      ],
      dialogFormVisible: false,
      form: {
        companyName:'',
        contactsName:'',
        phone:'',
      },
      rules: {
          companyName: [
            { required: true, message: '请输入企业名称', trigger: 'change' },
          ],
          contactsName: [
            { required: true, message: '请输入联系人', trigger: 'change' }
          ],
          phone: [
            { required: true, message: '请输入联系方式', trigger: 'change' }
          ],
        },
      formLabelWidth: "95px",
    };
  },
  components: {
    topmenu,
    bottominfo,
    contact,
    nocont,
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    if (this.id == "1") {
      this.maptitle = "参展企业";
      this.comname = "enterprise";
    } else {
      this.maptitle = "观展企业";
      this.comname = "enterprise2";
    }
    this.getinformation();
  },
   computed: {
      defaultOption () {
          return {
              step: 0.4, // 数值越大速度滚动越快
              limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
              hoverStop: true, // 是否开启鼠标悬停stop
              direction: 1, // 0向下 1向上 2向左 3向右
              openWatch: true, // 开启数据实时监控刷新dom
              singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
              singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
              waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
          }
      },
      
  },　
  watch: {
    $route: function (to, from) {
      this.id = this.$route.query.id;
      if (this.id == "1") {
        this.maptitle = "参展企业";
        this.comname = "enterprise";
      } else {
        this.maptitle = "观展企业";
        this.comname = "enterprise2";
      }
      this.getinformation();
    },
    //监听翻页
    currentPage: function (newVal, oldVal) {
      this.loading = true;
      this.getinformation();
    },
  },
  methods: {
    handleSizeChange: function (size) {
      //翻页
      this.pagesize = size;
    },
    handleCurrentChange: function (currentPage) {
      //翻页
      this.currentPage = currentPage;
    },
    noFollow (n) {
            if(n && n.indexOf("086") == -1)
            {
              return 'nofollow'
            }
            else{
             return ''
            }
        
      },
    // 保存
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.post("/api/rally/saveExhibitionApply", this.form).then(res => {
              if(res.data.isSuccess==1){
                this.$message({
                  message: '申请成功',
                  center: true,
                  type: 'success'
                });
                this.dialogFormVisible = false
                this.$refs[formName].resetFields();
              }else{
                this.$message.error(res.data.message);
              }
            })
          } else {
            // this.$message({message:'请检查表单是否填写正确!',center: true,type:'error'});
            return false;
          }
        });
      },
      // 重置
      resetForm(formName) {
        this.dialogFormVisible = false
        this.$refs[formName].resetFields();
      },
    getinformation() {
      //参展企业
      let data = {
        current: this.currentPage,
        size: this.pagesize,
        isPage: true,
        itype: this.id,
      };
      console.log(data);
      this.$axios
        .post("/api/app/exhibitionCompany/getExhibitionCompanyPageList", data)
        .then((res) => {
          this.loading = false;
          this.totalPage = res[1].pages;
          if (res[0].length > 0) {
            let infordata = res[0];
            console.log(infordata,"1111111111");
            this.list = infordata;
            this.isshowlist = true;
          } else {
            this.isshowlist = false;
          }
        });
    },
  },
};
</script>
<style scoped>
.flex {
  display: flex;
}
/deep/.el-dialog__header {
  /* float: left;
  line-height: 5px; */
  text-align: left;
}
/deep/.el-dialog__header {
  padding: 15px 20px 15px;
  background: #f8f8f8;
}
/deep/.el-dialog__title {
  font-size: 16px;
  line-height: inherit;
}
.apply {
  text-align: right;
  padding: 20px;
}
.el-button--goon.is-active,
.el-button--goon:active {
  background: #073290;
  border-color: #073290;
  color: #fff;
}

.el-button--goon:focus,
.el-button--goon:hover {
  background: #395ba6;
  border-color: #395ba6;
  color: #fff;
}

.el-button--goon {
  color: #fff;
  background-color: #073290;
  border-color: #073290;
  font-size: 15px;
}

.listinfor {
  padding-bottom: 20px;
  overflow: hidden;
}
.listinfor ul {
  display: block;
  overflow: hidden;
}
.listinfor li {
  margin-left: 15px;
  margin-bottom: 15px;
  float: left;
  width: 185px;
  border: solid 1px #e4e4e4;
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
}
.listinfor li .el-image {
  display: block;
  overflow: hidden;
  border-bottom: solid 1px #e4e4e4;
}
.listinfor li span {
  display: block;
  line-height: 24px;
  height: 48px;
  padding: 5px 5px;
}
.t_page {
  margin-top: 30px;
}
.bothendsleft {
  position: relative;
}
.bothendsright {
  position: absolute;
  right: 10%;
  width: 120px;
  margin-top: 47px;
  overflow: hidden;
  left: 50%;
  margin-left: 650px;
}
.seamless-warp {
  overflow: hidden;
  width: 120px;
  height: 300px;
}
.top-title{
  padding:15px 0;
  color: #fff;
  font-weight: bold;
  background: #073290;
}
.seamless-warp span{
  text-align: left;
  padding: 10px 15px;
}
.bothendsright li {
  background: #fff;
  border-top: solid 1px #e4e4e4;
  cursor: pointer;
}
.bothendsright li:hover{
  background: #e3edf9;
}
</style>