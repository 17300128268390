<template>
  <div>
    <div class="informatiton">
        <el-image :src="src" style="width:261px; height:201px;"></el-image>
        <div class="mt20"><span class="red">抱歉，</span>暂无相关信息。</div>
    </div>
  </div>
</template>
<script>
export default {
    name:"nocont",
    data() {
    return {
        src:require('@/assets/images/nocont.png')
    };
  }
};
</script>
<style scoped>
.red{
    color: #e70012;
}
.informatiton{
    padding: 100px 30px;
    color: #333;
    font-size: 16px;
}
.informatiton .el-image img{
    display: block;
    margin: 5px auto;
}
.mt20{
    margin-top: 20px;
}
</style>